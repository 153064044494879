import { BEARER_TOKEN, ENVIRONMENT, ENVIRONMENT_CONSTANTS } from '../../common/constants';
import { SRPStateInterface } from '../../common/context/srpInterface';
import { API_PATH } from '../../constants/api-constants';
import { PAGE_COUNT } from '../../constants/app-constants';
import { READ } from '../../services/actionTypes';
import { getAppliedFilter, getFormattedGroupArray } from '../../utils/filter.util';
import getUserCountryCookie from '../../utils/userCountry';
import { modifyPartnerListing } from '../../common/utilities';

const {
  API_V1, PARTNERS_LISTING, PARTNERS_FILTER, PARTNERS_FILTER_STATUS,
} = API_PATH;
const getDataArray = (data) => data.split(',') || [];
const getSelectedFilter = (query) => {
  const searchData = {};
  searchData.locations = query.locations ? getDataArray(query.locations) : [];
  searchData.languages = query.languages ? getDataArray(query.languages) : [];
  searchData.services = query.services ? getDataArray(query.services) : [];
  searchData.solutions = query.solutions ? getDataArray(query.solutions) : [];
  searchData.search = query.search || '';
  return searchData;
};
const getFiltersWithCountResponse = async (filtersData, filterStatus = {}, query = {}) => {
  let searchData = { ...SRPStateInterface.appliedFilter };
  if (query && Object.keys(query).length) {
    const selectedData = getSelectedFilter(query);
    searchData = { ...selectedData };
  }
  const pageCount = searchData?.pageCount || SRPStateInterface.appliedFilter.pageCount;
  const pageNumber = searchData?.pageNumber || SRPStateInterface.appliedFilter.pageNumber;
  const { filters } = getFormattedGroupArray({ filters: filtersData || {}, filterStatus });
  const selectedFilter = getAppliedFilter({ filters, appliedFilter: { ...searchData, pageCount, pageNumber } });
  return { filters, selectedFilter };
};
const getQueryPayload = (query) => {
  const {
    languages: lng = '', locations: loc = '', services: service = '', solutions: solution = '', search = '',
  } = query;
  return `${search ? `&search=${encodeURIComponent(search)}` : ''}${loc && `&locations=${encodeURIComponent(loc)}`}${lng && `&languages=${encodeURIComponent(lng)}`}${solution && `&solutions=${encodeURIComponent(solution)}`}${service && `&services=${encodeURIComponent(service)}`}`;
};
const getSRPData = async (result, initialState, query) => {
  const getError = (status) => (status !== 200);
  const filtersResponse = result[0] || {};
  const filtersData = filtersResponse?.data || {};
  const filterStatusResponse = result[1] || {};
  const filterStatusData = filterStatusResponse?.data || {};
  const listingResponse = modifyPartnerListing(result[2]);
  const resultData = { ...initialState, listPartners: { ...listingResponse?.data || initialState.listPartners } };
  if (result.length && Object.keys(filtersData).length) {
    const { filters, selectedFilter } = await getFiltersWithCountResponse(filtersData, filterStatusData || {}, query);
    resultData.filters = filters;
    resultData.appliedFilter = selectedFilter;
  }
  resultData.srpErrors.filterError = getError(filtersResponse?.status);
  resultData.srpErrors.filterStatusError = getError(filterStatusResponse?.status);
  resultData.srpErrors.partnersError = getError(listingResponse?.status);
  return resultData;
};
export default async (ctx) => {
  const { query, header: headers } = ctx.request || {};
  const { cookie } = headers;
  let payload = `page_count=${PAGE_COUNT}&page_no=1`;
  const env = process.env[ENVIRONMENT] || 'dev';
  const host = ENVIRONMENT_CONSTANTS[env].PARTNER_HOST;
  const partnerApiToken = process.env[BEARER_TOKEN];
  const header = {
    Authorization: `Bearer ${partnerApiToken}`,
  };
  if (query && Object.keys(query).length) {
    const param = getQueryPayload(query);
    payload = `${payload}${param}`;
  }

  const initialState = { ...SRPStateInterface, srpErrors: { filterError: false, filterStatusError: false, partnersError: false } };
  initialState.loading = false;
  let countryCode = '';
  try {
    countryCode = await getUserCountryCookie(cookie);
  } catch (e) { /* empty */ }
  const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
  const country = regionNamesInEnglish.of(countryCode);
  if (country && !(payload && (payload.includes('locations') || payload.includes('search')))) {
    payload += `&locations=${encodeURIComponent(country)}`;
  }
  if (country && !(Object.keys(query)?.length && Object.keys(query).includes('locations'))) {
    query.locations = country;
  }
  const filterApi = READ(`${host}${API_V1}${PARTNERS_FILTER}`, {}, header);
  const filtersStatusApi = READ(`${host}${API_V1}${PARTNERS_FILTER_STATUS}?${payload}`, {}, header);
  const listingApi = READ(`${host}${API_V1}${PARTNERS_LISTING}?${payload}`, {}, header);

  const promiseState = await Promise.all([filterApi, filtersStatusApi, listingApi]).then(async (result) => {
    const response = getSRPData(result, initialState, query);
    return response;
  });
  promiseState.env = env;
  return promiseState;
};
